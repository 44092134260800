import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"
import axios from "axios"

const initialState = {
    data: null,
    appliedJobData: null,
    loading: false,
    status: null,
    response: null,
    mailStatus : null,
}

export const getJobs = createAsyncThunk(
    "GetJobs",
    async (body) => {
        try {
            const response = await instance.get(`/remark/job/fetch-jobs?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const updateJob = createAsyncThunk(
    "UpdateJob",
    async (body) => {
        try {
            const response = await instance.post(`/remark/job/update-job?id=${body.id}`,body.data)
            return response.data;

        } catch (error) {

        }
    })


export const getAppliedJobs = createAsyncThunk(
    "GetAppliedJobs",
    async (body) => {
        try {
            const response = await instance.get(`/remark/job/fetch-applied-jobs?page=${body.page}&search=${body.search}&filter=${body.filter}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const updateAppliedJobStatus = createAsyncThunk(
    "updateAppliedJobStatus",
    async (body) => {
        try {
            const response = await instance.get(`/remark/job/update-applied-job-status?id=${body.id}&status=${body.status}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const JobSendToCandidate = createAsyncThunk(
    "JobSendToCandidate",
    async (body) => {
        try {
            const response = await instance.post('/remark/email/job-mail-candidate', body)
            return response
        } catch (error) {
            throw error;
        }
    }
)

export const blockJob = createAsyncThunk(
    "blockJob",
    async (body) => {
        try {
            const response = await instance.get(`/remark/job/block-job?id=${body.job_id}&job_deleted=${body.job_deleted}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const activeJob = createAsyncThunk(
    "activeJob",
    async (body) => {
        try {
            const response = await instance.get(`/remark/job/active-job?id=${body.job_id}&job_status=${body.job_status}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)


export const JobSlice = createSlice({
    name: "JobSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getJobs.pending, (state, action) => {
            state.loading = true;
            // state.data = null
            state.mailStatus = null;  
        });
        builder.addCase(getJobs.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getJobs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })


        builder.addCase(updateJob.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateJob.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(updateJob.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        
        builder.addCase(activeJob.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(activeJob.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(activeJob.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        
        builder.addCase(blockJob.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(blockJob.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(blockJob.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        
        
        builder.addCase(getAppliedJobs.pending, (state, action) => {
            state.loading = true;
            // state.data = null
        });
        builder.addCase(getAppliedJobs.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.appliedJobData = action.payload;
        });
        builder.addCase(getAppliedJobs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        
        builder.addCase(updateAppliedJobStatus.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateAppliedJobStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(updateAppliedJobStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        
        builder.addCase(JobSendToCandidate.pending, (state, action) => {
            state.loading = true;
            state.mailStatus = null;            
        });
        builder.addCase(JobSendToCandidate.fulfilled, (state, action) => {
            state.loading = false;
            state.mailStatus = action.payload;
        });
        builder.addCase(JobSendToCandidate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }) 

    }
})

export default JobSlice.reducer;