import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../config/Config";

const initialState = {
    loading: false,
    empList: null,
    empData: null,
    empCreate: null,
    error: null,
    emailTemplate: null,
    response: null,
    seletors: null,
}

export const GetEmployees = createAsyncThunk(
    "GetEmployees",
    async (body) => {
        try {
            const response = await instance.get(`/crm/employees/list-all?page=${body?.page}&fRole=${body?.role}&fProject=${body?.project}&fCompany=${body?.company}&fSearch=${body?.search}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    })

export const GetSelectors = createAsyncThunk(
    "GetSelectors",
    async () => {
        try {
            const response = await instance.get('/crm/employees/before-add-employee');
            return response.data;
        } catch (error) {
            throw error;
        }
    })

export const GetEmailTemplate = createAsyncThunk(
    "GetEmailTemplate",
    async () => {
        try {
            const response = await instance.get('/crm/employees/get-email-template');
            return response.data;
        } catch (error) {
            throw error;
        }
    })

export const GetEmployeeData = createAsyncThunk(
    "GetEmployeeData",
    async (slug) => {
        try {
            const response = await instance.get(`/crm/employees/get-employee-data?slug=${slug}`);
            return response.data
        }
        catch (error) {
            throw error;
        }
    }
)

export const CreateEmployee = createAsyncThunk(
    "CreateEmployee",
    async (body) => {
        try {
            const formData = new FormData();
            formData.append("e_first_name", body.e_first_name);
            formData.append("e_last_name", body.e_last_name);
            formData.append("e_username", body.e_username);
            formData.append("e_password", body.e_password);

            formData.append("e_aadhar_number", body.e_aadhar_number);
            formData.append("e_bd_account_holder", body.e_bd_account_holder);
            formData.append("e_bd_account_number", body.e_bd_account_number);
            formData.append("e_bd_bank_name", body.e_bd_bank_name);
            formData.append("e_bd_ifsc_code", body.e_bd_ifsc_code);
            formData.append("e_current_address", body.e_current_address);
            formData.append("e_designation", body.e_designation);
            formData.append("e_emergency_contact", body.e_emergency_contact);
            formData.append("e_dob", body.e_dob);
            formData.append("e_email_address", body.e_email_address);
            formData.append("e_experience_status", body.e_experience_status);
            formData.append("e_job_experience", JSON.stringify(body.e_job_experience));
            formData.append("e_join_date", body.e_join_date);
            formData.append("e_mobile_number", body.e_mobile_number);
            formData.append("e_pan_number", body.e_pan_number);
            formData.append("e_permanent_address", body.e_permanent_address);
            formData.append("e_pin_code", body.e_pin_code);
            formData.append("e_role", body.e_role);
            formData.append("e_slug", body.e_slug);
            formData.append("e_photo", body.e_photo[0]);
            formData.append("e_skills", body.e_skills);
            formData.append("e_project", body.e_project);
            formData.append("e_c_suit", body.e_c_suit);
            formData.append("e_project_show", JSON.stringify(body.e_project_show));
            formData.append("e_company", body.e_company);
            formData.append("e_team_leader", body.e_team_leader);

            if (typeof body?.e_documents == 'object') {
                // const newArr = [];
                for (let i = 0; i < body?.e_documents?.length; i++) {
                    // newArr.push(body?.e_documents[i])
                    formData.append("e_documents[]", body?.e_documents[i]);
                }
            }
            else {
                formData.append("e_documents", []);
            }

            const res = await instance.post("/crm/employees/register-employee", formData)
            return res.data
        } catch (error) {
            throw error
        }

    }
)

export const UpdateEmployee = createAsyncThunk(
    "UpdateEmployee",
    async (body) => {
        const formData = new FormData();
        formData.append("e_first_name", body.e_first_name);
        formData.append("e_last_name", body.e_last_name);
        formData.append("e_username", body.e_username);
        formData.append("e_aadhar_number", body.e_aadhar_number);
        formData.append("e_bd_account_holder", body.e_bd_account_holder);
        formData.append("e_bd_account_number", body.e_bd_account_number);
        formData.append("e_bd_bank_name", body.e_bd_bank_name);
        formData.append("e_bd_ifsc_code", body.e_bd_ifsc_code);
        formData.append("e_current_address", body.e_current_address);
        formData.append("e_designation", body.e_designation);
        formData.append("e_emergency_contact", body.e_emergency_contact);
        formData.append("e_dob", body.e_dob);
        formData.append("e_email_address", body.e_email_address);
        formData.append("e_experience_status", body.e_experience_status);
        formData.append("e_job_experience", JSON.stringify(body.e_job_experience));
        formData.append("e_join_date", body.e_join_date);
        formData.append("e_mobile_number", body.e_mobile_number);
        formData.append("e_pan_number", body.e_pan_number);
        formData.append("e_permanent_address", body.e_permanent_address);
        formData.append("e_pin_code", body.e_pin_code);
        formData.append("e_role", body.e_role);
        formData.append("e_slug", body.e_slug);
        formData.append("e_skills", body.e_skills);
        formData.append("e_project", body.e_project);
        formData.append("e_c_suit", body.e_c_suit);
        formData.append("e_project_show", JSON.stringify(body.e_project_show));
        formData.append("e_company", body.e_company);
        formData.append("e_team_leader", body.e_team_leader);

        if (typeof body.e_photo == 'string') {
            formData.append("e_photo", body.e_photo);
        }
        else {
            formData.append("e_photo", body.e_photo[0]);
        }

        if (typeof body?.e_documents == 'object') {
            // const newArr = [];
            for (let i = 0; i < body?.e_documents?.length; i++) {
                // newArr.push(body?.e_documents[i])
                formData.append("e_documents[]", body?.e_documents[i]);
            }
        }
        else {
            formData.append("e_documents", body?.e_documents);
        }


        try {
            const res = await instance.post(`/crm/employees/update-employee?slug=${body?.e_slug}`, formData)
            return res.data
        } catch (error) {
            throw error
        }
    }
)

export const sendMailToEmployee = createAsyncThunk(
    "sendMailToEmployee",
    async (body) => {
        try {
            const response = await instance.post('/remark/email/mail-send', body)
            return response
        } catch (error) {
            throw error;
        }
    }
)
export const employeeNotification = createAsyncThunk(
    "employeeNotification",
    async (body) => {
        try {
            const response = await instance.get(`/crm/employees/send-notification?e_id=${body.e_id}&message=${body.message}`);
            return response
        } catch (error) {
            throw error;
        }
    }
)



export const EmployeesSlice = createSlice({
    name: "EmployeesSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetEmployees.pending, (state) => {
            state.empCreate = null;
            state.loading = true;
            state.response = null;
        });
        builder.addCase(GetEmployees.fulfilled, (state, action) => {
            state.loading = false;
            state.empList = action.payload;
        });
        builder.addCase(GetEmployees.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(GetSelectors.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetSelectors.fulfilled, (state, action) => {
            state.loading = false;
            state.seletors = action.payload;
        });
        builder.addCase(GetSelectors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(GetEmailTemplate.pending, (state) => {
            state.empCreate = null;
            state.loading = true;
        });
        builder.addCase(GetEmailTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.emailTemplate = action.payload;
        });
        builder.addCase(GetEmailTemplate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(GetEmployeeData.pending, (state) => {
            state.empCreate = null;
            state.loading = true;
        });
        builder.addCase(GetEmployeeData.fulfilled, (state, action) => {
            state.loading = false;
            state.empData = action.payload;
        });
        builder.addCase(GetEmployeeData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(CreateEmployee.pending, (state) => {
            state.loading = true;
            state.empCreate = null;
        });
        builder.addCase(CreateEmployee.fulfilled, (state, action) => {
            state.loading = false;
            state.empCreate = action.payload;
        });
        builder.addCase(CreateEmployee.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(UpdateEmployee.pending, (state, action) => {
            state.loading = true;
            state.empCreate = null;
        });
        builder.addCase(UpdateEmployee.fulfilled, (state, action) => {
            state.loading = false;
            state.empCreate = action.payload;
        });
        builder.addCase(UpdateEmployee.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(sendMailToEmployee.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(sendMailToEmployee.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(sendMailToEmployee.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(employeeNotification.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(employeeNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(employeeNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    }

})

export default EmployeesSlice.reducer;