import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../config/Config";

const initialState = {
    loading: false,
    leadData: null,
    error: null,
    response: null,
    singleLead : null,
    checkStatusLoading: false,
}

export const GetLeadData = createAsyncThunk(
    "GetLeadData",
    async (body) => {
        try {
            const response = await instance.get(`/crm/leads/fetch-lead-data?page=${body.currentPage}&search=${body.search}&project=${body.project}&source=${body.source}&date=${body.date}&remark=${body.remark}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    })

export const AddLeadFile = createAsyncThunk(
    "AddLeadFile",
    async (body) => {
        const formData = new FormData();
        formData.append("excelfile", body);
        try {
            const res = await instance.post("/crm/leads/upload-excel", formData)
            return res.data
        } catch (error) {
            return error
        }

    }
)

export const getSingleLead = createAsyncThunk(
    "GetSingleLead",
    async (id) => {
        try {
            const response = await instance.get(`/crm/leads/fetch-single-lead?id=${id}`);
            return response?.data
        } catch (error) {
            return error
        }
    }
)

export const createLead = createAsyncThunk(
    "CreateLead",
    async (body) => {
        try {
            const response = await instance.post("/crm/leads/create-lead", body);
            return response?.data
        } catch (error) {
            return error
        }
    }
)

export const UpdateLead = createAsyncThunk(
    "UpdateLead",
    async (body) => {
        try {
            const response = await instance.post(`/crm/leads/lead-update?id=${body?.id}`, body?.data);
            return response?.data
        } 
        catch (error) {
            return error
        }
    }
)

export const callCountUpdate = createAsyncThunk(
    "callCountUpdate",
    async (id) => {
        try {
            const response = await instance.get(`/crm/leads/call-update?id=${id}`);
            return response?.data
        } 
        catch (error) {
            return error
        }
    }
)

export const checkStatusInstall = createAsyncThunk(
    "checkStatusInstall",
    async (body) => {
        try {
            const response = await instance.get(`/crm/leads/check-status-install?id=${body.id}&mobileNumber=${body.mobileNumber}&email=${body.email}`);
            return response?.data
        } 
        catch (error) {
            return error
        }
    }
)

export const getHistory = createAsyncThunk(
    "getHistory",
    async (body) => {
        try {
            const response = await instance.get(`/crm/leads/history?date=${body}`);
            return response?.data
        } 
        catch (error) {
            return error
        }
    }
)


export const LeadSlice = createSlice({
    name: "LeadSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(GetLeadData.pending, (state) => {
            // state.leadData = null;
            state.loading = true;
            state.response = null;
        });
        builder.addCase(GetLeadData.fulfilled, (state, action) => {
            state.loading = false;
            state.leadData = action.payload;
        });
        builder.addCase(GetLeadData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(AddLeadFile.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(AddLeadFile.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(AddLeadFile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.response = null;
        })

        builder.addCase(getSingleLead.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(getSingleLead.fulfilled, (state, action) => {
            state.loading = false;
            state.singleLead = action.payload;
        });
        builder.addCase(getSingleLead.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.response = null;
        })

        builder.addCase(createLead.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(createLead.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(createLead.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.response = null;
        })

        builder.addCase(UpdateLead.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(UpdateLead.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(UpdateLead.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.response = null;
        })

        builder.addCase(callCountUpdate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(callCountUpdate.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(callCountUpdate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.response = null;
        })

        builder.addCase(checkStatusInstall.pending, (state) => {
            state.checkStatusInstall = true;
        });
        builder.addCase(checkStatusInstall.fulfilled, (state, action) => {
            state.checkStatusInstall = false;
            state.response = action.payload;
        });
        builder.addCase(checkStatusInstall.rejected, (state, action) => {
            state.checkStatusInstall = false;
            state.error = action.error;
            state.response = null;
        })

        builder.addCase(getHistory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getHistory.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(getHistory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

    }

})

export default LeadSlice.reducer;