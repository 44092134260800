import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"
import axios from "axios"

const initialState = {
    data: null,
    loading: false,
    status: null,
    notification: null,
    verify: null,
    singleUser: null,
    updateUser: null,
    mailUser: null,
}

export const getNewCandidates = createAsyncThunk(
    "GetNewCandidates",
    async (body) => {
        try {
            const response = await instance.get(`/remark/user/fetch-new-candidates?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const getCandidates = createAsyncThunk(
    "GetCandidates",
    async (body) => {
        try {
            const response = await instance.get(`/remark/candidate/fetch-candidate-list?page=${body.page}&search=${body.search}&locSearch=${body.locSearch}&jobLocSearch=${body.jobLocSearch}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const getCompanies = createAsyncThunk(
    "GetCompanies",
    async (body) => {
        try {
            const response = await instance.get(`/remark/company/fetch-company-list?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const getConsultancies = createAsyncThunk(
    "GetConsultancies",
    async (body) => {
        try {
            const response = await instance.get(`/remark/user/fetch-consultancies?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const getUserDetails = createAsyncThunk(
    "GetUserDetails",
    async (id) => {
        try {
            const response = await instance.get(`/remark/user/single-user?id=${id}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const updateUserProfile = createAsyncThunk(
    "UpdateUserProfile",
    async (body) => {
        try {
            const formData = new FormData();
            // user details
            formData.append('user_name', body.data?.user_name)
            formData.append('user_profile', body.data?.user_profile);
            formData.append('user_email', body.data?.user_email);
            formData.append('user_organization_type', body.data?.user_organization_type);
            formData.append('user_mobile', body.data?.user_mobile);
            formData.append('user_bio', body.data?.user_bio);
            formData.append('user_skills', body.data?.user_skills);
            formData.append('user_languages', body.data?.user_languages);
            formData.append('user_type', body.data?.user_type);
            formData.append('user_organization', body.data?.user_organization);
            formData.append('user_qualifications', body.data?.user_qualifications);
            formData.append('user_address', body.data?.user_address);
            formData.append('user_city', body.data?.user_city);
            formData.append('user_location', body.data?.user_location);
            formData.append('user_job_location', body.data?.user_job_location);

            const response = await instance.post(`/remark/user/update-user?id=${body.id}`, formData);

            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const sendNotification = createAsyncThunk(
    "SendNotification",
    async (body) => {
        try {
            const formData = new FormData();
            formData.append("type", body.type);
            formData.append("title", body.title)
            formData.append("message", body.message)
            // formData.append("image", body.image[0])
            const response = await axios.post("https://testapi.remarkhr.com/notifications/custom-notifications", formData)
            return response
        } catch (error) {
            throw error;
        }
    }
)



export const sendMailToRemarkUser = createAsyncThunk(
    "sendMailToRemarkUser",
    async (body) => {
        try {
            const response = await instance.post('/remark/email/new-user-mail', body)
            return response
        } catch (error) {
            throw error;
        }
    }
)

export const userVerify = createAsyncThunk(
    "userVerify",
    async (body) => {
        try {
            const response = await instance.post(`/remark/user/verified?user_id=${body.userId}&isVerify=${body.isVerified}`)
            return response
        } catch (error) {
            throw error;
        }
    }
)

export const blockUserProfile = createAsyncThunk(
    "blockUserProfile",
    async (body) => {
        try {
            const response = await instance.get(`/remark/user/block-profile?id=${body.user_id}&user_deleted=${body.user_deleted}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const UserSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getNewCandidates.pending, (state, action) => {
            state.loading = true;
            state.notification = null;
            state.verify = null;


        });
        builder.addCase(getNewCandidates.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getNewCandidates.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(getCandidates.pending, (state, action) => {
            state.loading = true;
            state.notification = null;
            state.verify = null;

        });
        builder.addCase(getCandidates.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getCandidates.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(getCompanies.pending, (state, action) => {
            state.loading = true;
            state.notification = null;
            state.verify = null;

        });
        builder.addCase(getCompanies.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getCompanies.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(getConsultancies.pending, (state, action) => {
            state.loading = true;
            state.notification = null;
            state.verify = null;

        });
        builder.addCase(getConsultancies.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getConsultancies.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(getUserDetails.pending, (state, action) => {
            state.loading = true;
            state.notification = null;
            state.verify = null;

        });
        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.singleUser = action.payload;
        });
        builder.addCase(getUserDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(blockUserProfile.pending, (state, action) => {
            state.loading = true;
            state.updateUser = null;
        });
        builder.addCase(blockUserProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.updateUser = action.payload;
        });
        builder.addCase(blockUserProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(updateUserProfile.pending, (state, action) => {
            state.loading = true;
            state.notification = null;
            state.verify = null;

        });
        builder.addCase(updateUserProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.updateUser = action.payload;
        });
        builder.addCase(updateUserProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(sendNotification.pending, (state, action) => {
            state.loading = true;

        });
        builder.addCase(sendNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.notification = action.payload;
        });
        builder.addCase(sendNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(sendMailToRemarkUser.pending, (state, action) => {
            state.loading = true;
            state.mailUser = null;
        });
        builder.addCase(sendMailToRemarkUser.fulfilled, (state, action) => {
            state.loading = false;
            state.mailUser = action.payload;
        });
        builder.addCase(sendMailToRemarkUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(userVerify.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(userVerify.fulfilled, (state, action) => {
            state.loading = false;
            state.verify = action.payload;
        });
        builder.addCase(userVerify.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    }
})

export default UserSlice.reducer;