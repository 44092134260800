import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../config/Config";
import Cookies from 'js-cookie';

const initialState = {
    loading: false,
    data: null,
    error: null,
    logoutData: null,
}

export const loginAuth = createAsyncThunk(
    "loginAuth",
    async (data) => {

        const formData = new FormData();
        formData.append("username", data.username);
        formData.append("password", data.password);
        try {
            const response = await instance.post("/crm/auth/login", formData);
            if (response?.data?.token) {
                const now = new Date();
                const midnight = new Date(now.getFullYear(), now.getMonth(), (now.getDate() + 1)); // Midnight of the next day
                Cookies.set('authToken', response.data.token, { expires: midnight }); // Expires in 1 days
                // localStorage.setItem('authToken', response.data.token) 
                localStorage.setItem('fName', response.data.data.e_first_name);
                localStorage.setItem('lName', response.data.data.e_last_name)
                localStorage.setItem('designation', response.data.data.e_designation)

            }
            return response?.data;

        } catch (error) {
            return error;
        }
    })

export const logoutAuth = createAsyncThunk(
    "logoutAuth",
    async () => {
        try {
            const response = await instance.post("/crm/attendances/employee-checkout");
            // Remove a cookie
            
            Cookies.remove('authToken');
            localStorage.removeItem("authToken")
            localStorage.removeItem("fName")
            localStorage.removeItem("lName")
            localStorage.removeItem("designation")
            localStorage.removeItem("remarkPage")
            localStorage.removeItem("remarkPageNo")
            return response?.data
        } catch (error) {
            return error;
        }
    }
)

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(loginAuth.pending, (state, action) => {
            state.logoutData = null;
            state.loading = true;
        });
        builder.addCase(loginAuth.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(loginAuth.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(logoutAuth.pending, (state, action) => {
            state.data = null;
            state.loading = true;
        });
        builder.addCase(logoutAuth.fulfilled, (state, action) => {
            state.loading = false;
            state.logoutData = action.payload;
        });
        builder.addCase(logoutAuth.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    }

})

export default authSlice.reducer;