import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"
import axios from "axios";

const initialState = {
    data: null,
    loading: false,
    error: null,
    singleCli: null,
    respStatus: null,
}

export const fetchClients = createAsyncThunk(
    "fetchClients",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/client/fetch-all-client?search=${body.search}&page=${body.page}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const fetchSingleClient = createAsyncThunk(
    "fetchSingleClient",
    async (id, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/client/fetch-single-client?id=${id}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)
export const updateClientKyc = createAsyncThunk(
    "updateClientKyc",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post(`/fraction/client/update-kyc?id=${body.id}`,body.data);
            const notification = await axios.get(`https://admin.fractionrealty.in/notification/send-notification?fcm_token=${body.fcm_token}&refresh=0&message=${body.data.c_reason}&title=KYC Update`)
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const insertClient = createAsyncThunk(
    "insertClient",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post('/fraction/client/insert-client',body);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const updateClient = createAsyncThunk(
    "updateClient",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post(`/fraction/client/update-client?id=${body.id}`, body.data);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const ClientSlice = createSlice({
    name: 'ClientSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClients.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(fetchClients.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        builder.addCase(fetchClients.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(fetchSingleClient.pending, (state, action) => {
            state.loading = true;
            state.singleCli = null;
            state.respStatus = null;
        })
        builder.addCase(fetchSingleClient.fulfilled, (state, action) => {
            state.loading = false;
            state.singleCli = action.payload;
        })
        builder.addCase(fetchSingleClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(insertClient.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(insertClient.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(insertClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(updateClient.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(updateClient.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(updateClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(updateClientKyc.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(updateClientKyc.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(updateClientKyc.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

    },

})

export default ClientSlice.reducer;