import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    status: null,
    response: null,
    singleEmployer: null,
    mailUser: null
}

export const getEmployerList = createAsyncThunk(
    "GetEmployerList",
    async (body) => {
        try {
            const response = await instance.get(`/remark/employer/fetch-employer-list?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)


export const getEmployerProfile = createAsyncThunk(
    "getEmployerProfile",
    async (id) => {
        try {
            const response = await instance.get(`/remark/employer/fetch-profile?id=${id}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const updateEmployerProfile = createAsyncThunk(
    "updateEmployerProfile",
    async (body) => {
        try {
            const response = await instance.post(`/remark/employer/update-employer?id=${body.id}`,body.data)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const MailSendToEmployer = createAsyncThunk(
    "MailSendToEmployer",
    async (body) => {
        try {
            const response = await instance.post('/remark/email/mail-for-employer',body)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const sendMailToNewCompany = createAsyncThunk(
    "sendMailToNewCompany",
    async (body) => {
        try {
            const response = await instance.post('/remark/email/to-new-company', body)
            return response
        } catch (error) {
            throw error;
        }
    }
)

export const VerifyReqMail = createAsyncThunk(
    "VerifyReqMail",
    async (body) => {
        try {
            const response = await instance.post('/remark/email/verify-request-employer', body)
            return response
        } catch (error) {
            throw error;
        }
    }
)

export const EmployerSlice = createSlice({
    name: "EmployerSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getEmployerList.pending, (state, action) => {
            state.loading = true;
            state.response = null;
            state.mailUser = null
        });
        builder.addCase(getEmployerList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.status = action.payload.status;
        });
        builder.addCase(getEmployerList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(getEmployerProfile.pending, (state, action) => {
            state.loading = true;
            state.response = null;
            state.singleEmployer = null;
            state.mailUser = null;
        });
        builder.addCase(getEmployerProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.singleEmployer = action.payload;
        });
        builder.addCase(getEmployerProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(updateEmployerProfile.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(updateEmployerProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(updateEmployerProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(MailSendToEmployer.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(MailSendToEmployer.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(MailSendToEmployer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(sendMailToNewCompany.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(sendMailToNewCompany.fulfilled, (state, action) => {
            state.loading = false;
            state.mailUser = action.payload;
        });
        builder.addCase(sendMailToNewCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        builder.addCase(VerifyReqMail.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(VerifyReqMail.fulfilled, (state, action) => {
            state.loading = false;
            state.mailUser = action.payload;
        });
        builder.addCase(VerifyReqMail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        
    }
})

export default EmployerSlice.reducer;