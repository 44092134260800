import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    lunchEndResponse:null,
    data:null,
    loading:false,
    error:null,
    status:null,
    response:null
}

export const getAttendance = createAsyncThunk(
    "GetAttendance",async (body) => {
        try {
            const response = await instance.get(`/crm/attendances/fetch-all-attendances?page=${body.page}&employeeFilter=${body.employeeFilter}&monthFilter=${body.monthFilter}&dateFilter=${body.dateFilter}&companyFilter=${body.companyFilter}&typeFilter=${body.typeFilter}&yearFilter=${body.yearFilter}`)
            return response.data
        } catch (error) {
            return error
        }
    }
)

export const lunchStartNow = createAsyncThunk(
    "LunchStartNow",async () => {
        try {
            const response = await instance.get("/crm/attendances/lunch-start")
            return response.data
        } catch (error) {
            return error
        }
    }
)

export const lunchEnd = createAsyncThunk(
    "LunchEnd",async () => {
        try {
            const response = await instance.get("/crm/attendances/lunch-end")
            return response.data
        } catch (error) {
            return error
        }
    }
)

export const removeAttendance = createAsyncThunk(
    "removeAttendance",async (id) => {
        try {
            const response = await instance.get(`/crm/attendances/delete-attendance?id=${id}`)
            return response.data
        } catch (error) {
            return error
        }
    }
)

export const updateAttendance = createAsyncThunk(
    "updateAttendance",async (body) => {
        try {
            const response = await instance.post(`/crm/attendances/update-attendance?id=${body.id}`,body.data)
            return response.data
        } catch (error) {
            return error
        }
    }
)

export const attendanceSlice = createSlice({
    name: "AttendanceSlice",
    initialState,
    reducers:{},
    extraReducers: (builder) => {

        builder.addCase(getAttendance.pending,(state,action) => {
            state.loading = true;
            state.data = null;
        });
        builder.addCase(getAttendance.fulfilled,(state,action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getAttendance.rejected,(state,action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(lunchStartNow.pending,(state,action) => {
            state.loading = true;
            state.response = null;
            state.lunchEndResponse = null;
        });
        builder.addCase(lunchStartNow.fulfilled,(state,action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(lunchStartNow.rejected,(state,action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(lunchEnd.pending,(state,action) => {
            state.loading = true;
            state.lunchEndResponse = null;
        });
        builder.addCase(lunchEnd.fulfilled,(state,action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.lunchEndResponse = action.payload;
        });
        builder.addCase(lunchEnd.rejected,(state,action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(removeAttendance.pending,(state,action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(removeAttendance.fulfilled,(state,action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(removeAttendance.rejected,(state,action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(updateAttendance.pending,(state,action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(updateAttendance.fulfilled,(state,action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(updateAttendance.rejected,(state,action) => {
            state.loading = false;
            state.error = action.payload;
        });
        
    }
})

export default attendanceSlice.reducer;