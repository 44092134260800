import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    data: null,
    loading: false,
    error: null,
    response: null,
}

export const fetchLayout = createAsyncThunk(
    "fetchLayout",
    async () => {
        try {
            const response = await instance.get(`crm/layout/fetch-layout?project=remark`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const updateLayout = createAsyncThunk(
    "updateLayout",
    async (body) => {
        try {
            const res = await instance.post(`/crm/layout/update-layout?id=${body.id}`, body.data)
            return res.data
        } catch (error) {
            throw error
        }
    }
)



export const LayoutSlice = createSlice({
    name: "LayoutSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLayout.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(fetchLayout.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchLayout.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(updateLayout.pending, (state) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(updateLayout.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(updateLayout.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });
    }
})

export default LayoutSlice.reducer;